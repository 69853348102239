import React from "react"

import Layout from "components/Blog/Layout"
import Landing from "./Landing"
import { MainContentMaxWidth } from "components/Layouts/MaxWidth";
import ContentGroup from "./ContentGroup";
import Block from "components/CallToAction/Block"

const StoryPage = (props) => {
	const {
		title, img, text, heroes, prisms, factions
	} = props

	return (
		<Layout ignoreTopPadding>
			<Landing title={title} text={text} img={img} />
			<ContentGroup type="hero" {...heroes} />
			<ContentGroup type="prism" {...prisms} />
			<ContentGroup type="faction" {...factions} />

			<MainContentMaxWidth>
				<Block title={"GET STARTED TO PLAY"} text="Create your account to start playing" buttonLink={"/get-started"} buttonTitle="STEPS TO GET STARTED
" />
			</MainContentMaxWidth>
		</Layout>
	)
}

export default StoryPage