import React from "react"
import styled from "styled-components"

import { MedMaxWidth } from "components/Layouts/MaxWidth"
import { H2, P } from "components/Typography"
import { BackgroundImage } from "components/Layouts/Utils"

const StyledLanding = styled.section`
    position: relative;
    width: 100%;
    padding: 18rem 8rem;
    padding-top: 12rem;
    margin-bottom: 4rem;
    overflow: hidden;
		border-bottom: 1px solid var(--lightBorder);

		p {
			margin-bottom: 1rem;
		}

    @media (max-width: 768px) {
        padding-top: 8rem;
        padding-bottom: 15rem;
        text-align: left;
        padding-left: 0rem;
        padding-right: 0rem;
    }
`

const Landing = ({
	title, text, img
}) => {
	return (
		<StyledLanding>

			<BackgroundImage
				src={img.url}
				loading="lazy"
			/>

			<MedMaxWidth>
				<H2 color="white">{title}</H2>
				<P><div dangerouslySetInnerHTML={{ __html: text }} /></P>
			</MedMaxWidth>
		</StyledLanding>
	)
}

export default Landing