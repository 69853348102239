import React from "react"
import { Dialog as ReachDialog } from "@reach/dialog";
import styled from "styled-components"

import "@reach/dialog/styles.css";
import { H2, P } from "components/Typography"

const Close = () => <svg width="36" height="38" viewBox="0 0 36 38" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path fillRule="evenodd" clipRule="evenodd" d="M1 1H35V37H7L1 31V1Z" fill="url(#paint0_linear_801_10463)" />
	<path d="M35 1V0.5H35.5V1H35ZM1 1H0.5V0.5H1V1ZM35 37H35.5V37.5H35V37ZM7 37V37.5H6.79289L6.64645 37.3536L7 37ZM1 31L0.646447 31.3536L0.5 31.2071V31H1ZM35 1.5H1V0.5H35V1.5ZM34.5 37V1H35.5V37H34.5ZM7 36.5H35V37.5H7V36.5ZM1.35355 30.6464L7.35355 36.6464L6.64645 37.3536L0.646447 31.3536L1.35355 30.6464ZM1.5 1V31H0.5V1H1.5Z" fill="#705BAB" />
	<path d="M17.0156 18.9844L13.6406 15.6406C13.4531 15.4531 13.4531 15.1094 13.6406 14.9219L14.4219 14.1406C14.6094 13.9531 14.9531 13.9531 15.1406 14.1406L18.4844 17.5156L21.8594 14.1406C22.0469 13.9531 22.3906 13.9531 22.5781 14.1406L23.3594 14.9219C23.5469 15.1094 23.5469 15.4531 23.3594 15.6406L19.9844 18.9844L23.3594 22.3594C23.5469 22.5469 23.5469 22.8906 23.3594 23.0781L22.5781 23.8594C22.3906 24.0469 22.0469 24.0469 21.8594 23.8594L18.4844 20.4844L15.1406 23.8594C14.9531 24.0469 14.6094 24.0469 14.4219 23.8594L13.6406 23.0781C13.4531 22.8906 13.4531 22.5469 13.6406 22.3594L17.0156 18.9844Z" fill="white" />
	<defs>
		<linearGradient id="paint0_linear_801_10463" x1="18.1192" y1="17.5919" x2="18.1192" y2="17.8443" gradientUnits="userSpaceOnUse">
			<stop stopColor="#2E2152" />
			<stop offset="1" stopColor="#241844" />
		</linearGradient>
	</defs>
</svg>

const PopupBackground = styled.button`
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 2;
`

const PopupContent = styled.div`
    width: 100%;
		border: 1px solid var(--lightBorder);
    background-color: var(--background);

    max-width: 30rem;
    width: 100%;
    z-index: 3;
    position: relative;
`
const CloseButton = styled.button`
    position: absolute;
    top: -1px;
    right: -1px;
    z-index: 8;
`

const Container = styled.div`
		height: 0;
		width: 100%;
		padding-bottom: 100%;

		.cover {
			position: absolute;top:0;left:0;height:100%;width:100%;
			object-fit: cover;
			object-position: top center;
		}
`

const TextGroup = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: flex-end;
	z-index: 4;
	justify-content: space-between;

	.text-block {
		padding: 1.5rem;
		padding-top: 4rem;
		position: relative;
		z-index:5;
		background: linear-gradient(180deg, rgba(35, 20, 69, 0) 0%, rgba(35, 20, 69, 0.9) 30.92%);
	}

	&::before {
		content: "";
		z-index: 2;
		height: 70%;
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
	}

	.prism-icon {
		width: 30px;
		margin-right: 10px;
	}

	.prism {
		margin-bottom: 0.5rem;
		display: flex;
		align-items: center;
	}
`


const Popup = (props) => {
	const {
		showDialog, close, small = false,
		name, alternateName, type, image, foundPrism, text, quote
	} = props

	return (
		<div>
			<ReachDialog
				aria-label="Dialog"
				isOpen={showDialog} onDismiss={close}
			>
				<>
					<PopupBackground onClick={close} />
					<PopupContent small={small}>
						<Container>
							<img alt="" className="cover" src={`/images${image}`} loading="lazy" />

							<TextGroup>
								{type !== "prism" ? (
									<div className="text-block">
										{foundPrism && (
											<div className="prism">
												<img alt="" loading="lazy" className="prism-icon" src={`/images/prisms/${foundPrism.icon}.png`} />
												<H2 style={{ marginBottom: 0 }} size="16px" color="white">{foundPrism.text} Skyweaver</H2>
											</div>
										)}

										<H2
											size="40px"
											color="white"
											style={{
												marginBottom: "0.5rem"
											}}
										>
											<span>{name}</span>
											{alternateName && <span
												style={{
													fontSize: "25px",
													marginLeft: "10px"
												}}>{alternateName}</span>}
										</H2>

										{text && <P style={{
											marginBottom: "0.5rem",
											lineHeight: "1.3"
										}} color="var(--lilac)">{text}</P>}
										{quote && <P style={{ marginBottom: "0" }} color="#AC8FFF"><em>"{quote}"</em></P>}
									</div>
								) : (
									<div className="text-block">

										<H2
											size="40px"
											color="white"
											style={{
												marginBottom: "0.5rem"
											}}
										>
											<span>{name}</span>
											{alternateName && <span
												style={{
													fontSize: "25px",
													marginLeft: "10px"
												}}>{alternateName}</span>}
										</H2>

										{text && <P style={{
											marginBottom: "0.5rem",
											lineHeight: "1.3"
										}} color="var(--lilac)">{text}</P>}
										{quote && <P style={{ marginBottom: "0" }} color="#AC8FFF"><em>"{quote}"</em></P>}

									</div>
								)}
							</TextGroup>

						</Container>
						<CloseButton onClick={close}>
							<Close />
						</CloseButton>
					</PopupContent>
				</>
			</ReachDialog>
		</div>
	)
}

export default Popup
