import React from "react";

import "css/main.css";
import "css/fonts.css";

import Seo from "components/SeoHead";
import StoryPage from "components/Story"

// title, content, footer
import { data } from "data/lore"

function GetStartedPage() {
	return (
		<>
			<Seo />
			<StoryPage {...data} />
		</>
	)
}

export default GetStartedPage