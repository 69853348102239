import React, { useMemo, useState } from "react"
import styled from "styled-components"

import FeaturedTitle from "components/Blog/FeaturedHeader"
import { MainContentMaxWidth } from "components/Layouts/MaxWidth"
import { P, H2 } from "components/Typography"
import { FlexParent } from "components/Layouts/Utils"
import { ELEMENTS, PRISMS } from "data/lore"
import Popup from "./Popup"

const Section = styled.section`
	padding: 0rem 0;
	text-align: center;
	margin-bottom: 4rem;
`
const MaxWidth = styled.div`
	max-width: 60rem;
	margin: auto;
	padding: 1rem 2rem;
	padding-top: 0.5rem;
`

const Block = styled.button`
	width: ${props => props.type === "faction" ? "33.33%" : "20%"};
	padding: 0.5rem;
	text-align: left;

	@media (max-width: 900px) {
		width: 33.33%;
	}

	@media (max-width: 650px) {
		width: 100%;
	}
`

const Item = styled.div`
	position: relative;
	height: 0;
	padding-bottom: ${props => props.type === "faction" ? "60%" : "120%"};
	border: 1px solid var(--lightBorder);
	background: white;
	overflow: hidden;

	@media (max-width: 600px) {
		padding-bottom: 100%;
	}

	&:hover .cover {
		transform: scale(1.045);
	}

	.element-icon {
		width: 30px;
	}

	.prism-icon {
		width: 45px;
	}

	.cover {
		transition: 0.3s ease-out transform;
		will-change: transform;
		position: absolute;
		top: -1%;left: -1%; height: 102%;
		width: 102%;
		object-fit: cover;
		object-position: top center;
	}
`

const TextGroup = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	height: 100%;
	width: 100%;
	display: flex;
	padding: 1rem;
	align-items: flex-end;
	justify-content: space-between;
	z-index: 2;
	background: linear-gradient(rgba(12, 6, 30, 0) 40%, rgba(12, 6, 30, 1) 100%);
`

const ContentBlock = (props) => {
	const { name, alternateName, type, image, faction, prism } = props
	const [popupOpen, setPopup] = useState(false)

	const open = () => {
		setPopup(true)
	}

	const close = () => {
		setPopup(false)
	}

	const foundFaction = useMemo(() => {
		return ELEMENTS.find(item => item.icon === faction)
	}, [faction])

	const foundPrism = useMemo(() => {
		return PRISMS.find(item => item.title === prism)
	}, [prism])

	return (
		<>
			<Popup
				type={type}
				showDialog={popupOpen}
				open={open}
				foundFaction={foundFaction}
				foundPrism={foundPrism}
				close={close} {...props} />

			<Block onClick={open} type={type}>

				<Item style={{
					borderColor: (foundFaction && foundFaction.color) || ""
				}} type={type}>

					<img alt="" className="cover" src={`/images${image}`} loading="lazy" />

					<TextGroup>
						{type !== "prism" ? (
							<>
								<div>
									<H2 style={{ marginBottom: 0 }} size="30px" color="white">{name}</H2>
									{alternateName && <H2 style={{ marginBottom: 0 }} color="white" size="14px">{alternateName}</H2>}
								</div>
								<div>
									{foundFaction && <img alt="" loading="lazy" className="element-icon" src={`/images/elements/${foundFaction.icon}.png`} />}

									{foundPrism && <img alt="" loading="lazy" className="prism-icon" src={`/images/prisms/${foundPrism.icon}.png`} />}
								</div>
							</>
						) :
							<H2 style={{ textAlign: "center", width: "100%" }} size="35px" color="white">{name}</H2>
						}

					</TextGroup>
					{/* <Gradient /> */}
				</Item>
			</Block>
		</>
	)
}

const ContentGroup = (props) => {
	const { title, text, type, content } = props

	return (
		<Section>
			<FeaturedTitle>{title}</FeaturedTitle>
			<MaxWidth>
				<P dangerouslySetInnerHTML={{ __html: text }} />
			</MaxWidth>
			<MainContentMaxWidth style={{
				marginTop: 0,
				marginBottom: "2rem",
				paddingTop: "1rem"
			}}>
				<FlexParent>
					{content?.map((block, i) => <ContentBlock type={type} key={i} {...block} />)}
				</FlexParent>
			</MainContentMaxWidth>
		</Section>
	)
}

export default ContentGroup